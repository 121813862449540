exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-publica-index-js": () => import("./../../../src/pages/api-publica/index.js" /* webpackChunkName: "component---src-pages-api-publica-index-js" */),
  "component---src-pages-caracteristicas-colaboracion-index-js": () => import("./../../../src/pages/caracteristicas/colaboracion/index.js" /* webpackChunkName: "component---src-pages-caracteristicas-colaboracion-index-js" */),
  "component---src-pages-caracteristicas-financiero-index-js": () => import("./../../../src/pages/caracteristicas/financiero/index.js" /* webpackChunkName: "component---src-pages-caracteristicas-financiero-index-js" */),
  "component---src-pages-caracteristicas-gestion-de-tareas-index-js": () => import("./../../../src/pages/caracteristicas/gestion-de-tareas/index.js" /* webpackChunkName: "component---src-pages-caracteristicas-gestion-de-tareas-index-js" */),
  "component---src-pages-caracteristicas-index-js": () => import("./../../../src/pages/caracteristicas/index.js" /* webpackChunkName: "component---src-pages-caracteristicas-index-js" */),
  "component---src-pages-caracteristicas-planificacion-y-pronostico-index-js": () => import("./../../../src/pages/caracteristicas/planificacion-y-pronostico/index.js" /* webpackChunkName: "component---src-pages-caracteristicas-planificacion-y-pronostico-index-js" */),
  "component---src-pages-caracteristicas-vista-de-pajaro-index-js": () => import("./../../../src/pages/caracteristicas/vista-de-pajaro/index.js" /* webpackChunkName: "component---src-pages-caracteristicas-vista-de-pajaro-index-js" */),
  "component---src-pages-contactenos-index-js": () => import("./../../../src/pages/contactenos/index.js" /* webpackChunkName: "component---src-pages-contactenos-index-js" */),
  "component---src-pages-equipo-index-js": () => import("./../../../src/pages/equipo/index.js" /* webpackChunkName: "component---src-pages-equipo-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-missing-translation-index-js": () => import("./../../../src/pages/missing-translation/index.js" /* webpackChunkName: "component---src-pages-missing-translation-index-js" */),
  "component---src-pages-planes-de-precios-index-js": () => import("./../../../src/pages/planes-de-precios/index.js" /* webpackChunkName: "component---src-pages-planes-de-precios-index-js" */),
  "component---src-pages-politica-de-privacidad-index-js": () => import("./../../../src/pages/politica-de-privacidad/index.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-index-js" */),
  "component---src-pages-terminos-de-servicio-index-js": () => import("./../../../src/pages/terminos-de-servicio/index.js" /* webpackChunkName: "component---src-pages-terminos-de-servicio-index-js" */),
  "component---src-templates-blog-blog-index-js": () => import("./../../../src/templates/blog/blog-index.js" /* webpackChunkName: "component---src-templates-blog-blog-index-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/blog-post.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-features-feature-details-js": () => import("./../../../src/templates/features/feature-details.js" /* webpackChunkName: "component---src-templates-features-feature-details-js" */)
}

